import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const IntercomProvider = ({ user }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://widget.intercom.io/widget/iwu64wwe`; 
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: 'iwu64wwe',
          user_id: user.id,
          name: user.name,
          email: user.email,
          created_at: user.createdAt,
        });
      }
    };

    return () => {
      document.body.removeChild(script);
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  return null; 
};

IntercomProvider.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default IntercomProvider;


