import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import logo from 'styles/images/sysco-logo.png';
import UserContext from 'context/User';
import userRoles from 'enums';
import UserWidget from './UserWidget';
import VendorSwitcher from './VendorSwitcher';
import AppDrawerDropdown from './AppDrawerDropdown';
import Notification from '../notifications'
import { getAlreadyReadBanners, setAlreadyReadBanners, getUserType } from '../../util/helpers';
import { readBanners } from '../../helpers/UserUtil';
import { isMobile } from 'react-device-detect';
import IntercomProvider from 'components/AppBar/Intercom';

const user = {
  user_id: 'apus8873', // Replace with your user's ID variable
  name: 'Aneshvardan Pushparaj', // Replace with your user's name variable
  email: 'Aneshvardan.Pushparaj@sysco.com', // Replace with your user's email variable
  created_at: 1686691033, // Replace with your user's sign-up date variable (Unix timestamp in seconds)
};

function handleLogoClick() {
  window.location.href = `/suite/dashboard`;
}
function AppBar({ selectVendor }) {
  const [alreadyReadBanners, setReadBanners] = useState(getAlreadyReadBanners() || []);
  const { selectedVendor, userRole, banners } = useContext(UserContext);

  const mobileInlineStyle = (mobileStyle, webStyle) => {
    webStyle = webStyle || {};
    return isMobile ? mobileStyle : webStyle;
  };

  
  const handleBannerClose = useCallback(async (id) => {
    try {
        const currentReadBanners = getAlreadyReadBanners();
        const readBannersCount = [...new Set([...currentReadBanners, id])]
        setAlreadyReadBanners('banners', JSON.stringify(readBannersCount));
        setReadBanners(readBannersCount)
        await readBanners(id);
    } catch (error) {
      console.log('error updating read count', error);
    }
  }, []);

  return (
    <div id="app-bar" className="app-bar" style={mobileInlineStyle({justifyContent: 'flex-end'})} >
      { !isMobile ? <div className="branding ">
          <img src={logo} className="logo" alt="" onClick={handleLogoClick} />
          <div className="app-logo-wrapper">
            Supplier Suite
          </div>
        </div> : <div className="mobile-header">
          <img src={logo} onClick={handleLogoClick}/>
          <div className="brand-label">Supplier Suite</div>
        </div>
      }

      {userRole === userRoles.VENDOR && selectedVendor ? <VendorSwitcher selectVendor={selectVendor} /> : null}
      <div className="spacer" style={mobileInlineStyle({display: 'none'})} />
      <div className="account-widget">
        <div className="messages hide">
          <div className="label">Message</div>
          <span className="icon fi flaticon-mail" />
        </div>
        <div className="notifications hide">
          <span className="icon fi flaticon-bell" />
          <div className="count">99</div>
        </div>
        <AppDrawerDropdown />
        {/*<IntercomProvider user={user} />*/}
        <UserWidget selectVendor={selectVendor} />
      </div>
      <Notification banners={banners.filter(({ id }) => !alreadyReadBanners.includes(id))} handleBannerClose={handleBannerClose} />
    </div>
  );
}

AppBar.propTypes = {
  selectVendor: PropTypes.func
};

export default AppBar;
